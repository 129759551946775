
































import { Vue, Component } from "vue-property-decorator";
import { namespace } from "vuex-class";
import VModal from "vue-js-modal";
import moment from "moment";

import { CustomTable } from "@/components";
import { Batch, ListBatchResponse } from "@/proto/batch/all_pb";
import { formatCurrency } from "@/utils/common";

const transactionsModule = namespace("transactions");

Vue.use(VModal);

@Component({
  components: {
    CustomTable,
  },
})
export default class BatchCredit extends Vue {
  @transactionsModule.Action("getBatchTxns")
  getBatchTxns!: (batch: Array<string>) => ListBatchResponse.AsObject;

  isUploadFileVisible = false;
  columns = [] as any[];
  searchTerm = "";
  batchTxns: Array<Batch.AsObject> = [];

  created() {
    this.columns = [
      {
        field: "type",
        label: "Transaction",
        sortable: true,
      },
      {
        field: "reference",
        label: "Reference",
        sortable: true,
      },
      {
        field: "uploaddate",
        label: "Upload Date",
        sortable: true,
      },
      {
        field: "amount",
        label: "Amount",
        sortable: true,
      },
      {
        field: "status",
        label: "Status",
        sortable: false,
      },
    ];
  }

  mounted() {
    this.getBatchList();
  }

  async getBatchList() {
    try {
      const result = await this.getBatchTxns([]);
      this.batchTxns = result.batchList;
    } catch (error) {
      const e = error as Error;
      console.error(`Error on Batch Debit: ${e.message}`);
    }
  }

  get rows() {
    return this.batchTxns.map((txn) => {
      return {
        type: "Payroll Exeutive",
        reference: "N/A",
        uploaddate: txn.created.seconds,
        amount: `PHP ${this._generateTotalAmount(txn.transactionsList)}`,
        status: txn.status,
      };
    });
  }

  _generateTotalAmount(transactionsList: any[]): string {
    if (!transactionsList.length) {
      return "0.00";
    }

    let total = 0;

    transactionsList.forEach((txn) => {
      const amount = parseFloat(txn.amount.num) / 100;
      total += amount;
    });

    // return total.toFixed(2);
    return formatCurrency(total);
  }

  _batchCreatedDate(createdDate: number): string {
    if (!createdDate) return "N/A";
    return moment.unix(createdDate).format("DD MMM YYYY[\n] kk:mm [GMT]");
  }

  get sortableColumns() {
    return this.columns.filter((column) => !!column.sortable);
  }
}
